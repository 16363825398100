<template>
  <AppInput
    v-model="email"
    :placeholder="placeHolder"
    :validation-list="validationFields.email"
    show-validation-message
    :server-validation="serverValidationEmail"
    :onBlur="findEmail"
    :keyInput="'uniqueEmail'"
    @input="onEmailTyping"
  >
    <template
      v-if="loading"
      #rightIcon
    >
      <div style="width: 80px; height: 40px;margin-top: 24px; overflow: hidden">
        <AppLoader />
      </div>
    </template>
  </AppInput>
</template>

<script>
import AppInput from '@/components/common/simple/AppInput'
import { validateEmail } from '@/utils/validation'
import AppLoader from '@/components/AppLoader'

export default {
  name: 'UniqueEmail',
  components: { AppInput, AppLoader },
  props: {
    checkIfCanAdd: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
      default: 'Электронная почта',
    }
  },
  data () {
    return {
      email: null,
      emailFound: null,
      emailFoundStatus: null,
      serverValidationEmail: null,
      error: false,
      loading: false,
      validationFields: {
        email: [
          {
            text: 'Электронная почта формата example@mail.com',
            valid: true,
            validator: (value) => validateEmail(value)
          }
        ],
      },
    }
  },
  computed: {
    isValidUser () {
      return this.emailFound > 0 && this.emailFoundStatus === true
    },
    isInValidUser () {
      return this.emailFound > 0 && this.emailFoundStatus === false
    },
    isNewEmail () {
      return this.emailFound === 0 && this.emailFoundStatus === false
    },
    notActivateEmail () {
      this.$emit('emailStatus', 'inactive')
      return this.emailFound > 0 && this.emailFoundStatus === false
    },
    activateEmail () {
      this.$emit('emailStatus', 'active')
      return this.emailFound > 0 && this.emailFoundStatus === true
    },
  },
  created () {
    this.eventsBus.$on(this.eventNames.CLEAR_UNIQUE_EMAIL, this.clear)
  },
  methods: {
    clear () {
      this.emailFound = null
      this.serverValidationEmail = null
      this.emailFoundStatus = null
      this.email = ''
    },
    onEmailTyping (value, key) {
      this.emailFound = null
      this.serverValidationEmail = null
      this.emailFoundStatus = null
      const isValid = this.checkIfCanAdd ? this.isNewEmail : this.isValidUser
      this.$emit('emailValue', { value, isValid })
    },
    findEmail () {
      if (validateEmail(this.email)) {
        this.loading = true
        this.$store.dispatch('users/fetchCheckEmail', { email: this.email.trim() })
          .then((response) => {
            if (this.checkIfCanAdd) {
              this.emailFound = response.data.count
              this.emailFoundStatus = response.data.isActive
              if (this.emailFound > 0) {
                this.serverValidationEmail = [{ 'propertyPath': 'email', 'title': 'Данная почта уже есть в системе' }]
              } else {
                this.$emit('emailValue', { value: this.email, isValid: true })
                this.serverValidationEmail = null
              }
            } else {
              this.emailFound = response.data.count
              this.emailFoundStatus = response.data.isActive
              if (this.emailFound === 0) {
                this.serverValidationEmail = [{ 'propertyPath': 'email', 'title': 'Данная почта не найдена' }]
              } else {
                if (this.emailFoundStatus === false) {
                  this.serverValidationEmail = [{ 'propertyPath': 'email', 'title': 'Данная почта не подтверждена' }]
                } else {
                  this.$emit('emailValue', { value: this.email, isValid: true })
                  this.serverValidationEmail = null
                }
              }
            }
            this.loading = false
          })
          .catch((error) => {
            this.serverValidationEmail = error.data.violationss
            this.loading = false
          })
      }
    },

  }
}
</script>

<style lang="sass" scoped>
  .login

    &__header
      text-align: center
      margin-bottom: 30px

    &__form
      display: flex
      flex-direction: column
      &__button
        width: 90%
        margin: 25px auto 0 auto

    &__footer
      display: flex
      flex-direction: column
      text-align: center
      align-items: center

      &__password
        margin-top: 25px

    &__splice-line
      width: 100%
      height: 1px
      margin: 30px 0
      background-color: $line-color_gray
</style>
