<template>
  <AppMainContainer
    center-class="offer"
    :show-modal="!!currentModal"
    @isScroll="(val) => {isScroll = val}"
    @closeModal="closeModal"
  >
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot" />
      <div
        class="filter-top__element filter-button"
        @click="showModalCreateUser"
      >
        <IconPlus class="add__icon" />
        <span class="button-text">Пригласить нового пользователя</span>
      </div>
    </template>

    <template #center>
      <div style="flex: 1">
        <AppInput
          v-model="querySearch"
          middle
          style="margin-bottom: 2px; width: 100%"
          :placeholder="'Поиск пользователей по фамилию или почте'"
          :onBlur="onSearchBlur"
          :keyInput="'user-list-search'"
          @input="onSearch"
        />
      </div>
      <AppTable
        :keys="keys"
        :array="userList"
        :event="fetchUserList"
        :isShowLoad="loadList"
        @click="goToUserEdit"
      >
        <template #statusIcon="{item: user}">
          <div
            class="user-list__circle"
            :class="`user-list__circle_${user.status}`"
          />
        </template>
        <template #firstName="{item: user}">
          {{ user.lastName }} {{ user.firstName }} {{ user.middleName }}
        </template>
      </AppTable>
    </template>

    <template #modal>
      <AppModalMiddle
        v-if="currentModal === modalName.createUser"
        style="padding: 30px 50px; width: 716px"
      >
        <ModalHeader @close="closeModal">
          Приглашение нового пользователя
        </ModalHeader>
        <div class="user-form__input-group">
          <div style="display: inline-block; vertical-align: top; width: 49%; margin-right: 1%">
            <UniqueEmail
              style="margin-top: 15px;"
              checkIfCanAdd
              :placeHolder="'Электронная почта (обязательное поле)'"
              @emailValue="onEmailValueChange"
            />
          </div>
          <div
            v-for="field in userFields"
            :key="field.key"
            class="input-group__input"
            style="display: inline-block; vertical-align: top; width: 49%; margin-right: 1%"
          >
            <component
              :is="field.type"
              v-model="field.value"
              :placeholder="field.placeholder"
              :format="field.format"
              :values="selectedList[field.values]"
              :visualValue="field.value[field.visualValue]"
              :validationList="field.validator"
              :option-key="field.key"
              :showValidationMessage="field.showValidationMessage"
              @change="onFieldChange"
            >
              <!--              <template #default="{value: office}">-->
              <!--                {{ office.title }}-->
              <!--              </template>-->
              <template #default="{value: role}">
                {{ role.title }}
              </template>
            </component>
          </div>
          <OfficeMember
            v-if="currentModal === modalName.createUser"
            :office-list="officeList"
            :multiple="hasMultipleRoles"
            :selected-office-list.sync="selectedOfficeList"
          />
        </div>

        <div class="user-form__button-group">
          <AppLoader
            v-if="currentLoader === loaderData.USER_FORM"
            style="margin: 10px"
          />
          <AppButton
            v-if="currentLoader !== loaderData.USER_FORM"
            :disabled="!isFormValid"
            style="margin-left: auto"
            @click.native="inviteUser"
          >
            Пригласить пользователя
          </AppButton>
        </div>
      </AppModalMiddle>

      <AppModalSmall
        v-if="currentModal === modalName.successCreateUser"
        style="padding: 50px 76px; width: 395px"
      >
        <h1 class="modal__header">
          Пользователь создан
        </h1>
        <p class="modal__description">
          Приглашение отправлено на указанный
          адрес  электронной почты
        </p>
        <div class="modal__button">
          <AppButton
            @click.native="currentModal = ''"
          >
            Ок
          </AppButton>
        </div>
      </AppModalSmall>
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import OfferListHeader from '@/components/offer/OfferListHeader'
import officeApi from '@/mixins/office/officeApi'
import IconPlus from '@/components/common/icons/IconPlus'
import AppTable from '@/components/common/AppTable'
import AppModalMiddle from '@/components/common/modal/AppModalMiddle'
import ModalHeader from '@/components/common/modal/ModalHeader'
import AppInput from '@/components/common/simple/AppInput'
import AppSelect from '@/components/common/simple/AppSelect'
import AppButton from '@/components/common/simple/AppButton'
import { notEmptyField, ruPhoneValidator } from '@/utils/validation'
import AppModalSmall from '@/components/common/modal/AppModalSmall'
import AppLoader from '@/components/AppLoader'
import UniqueEmail from '@/components/common/UniqueEmail'
import OfficeMember from '@/components/common/office-member'
import userUtil from '@/utils/user-util'
import PageHeader from '@/components/subheaders/PageHeader.vue'

const modalName = Object.freeze({
  createUser: 'createUser',
  successCreateUser: 'successCreateUser',
})

export default {
  name: 'PageUserList',
  components: {
    PageHeader,
    OfficeMember,
    AppModalSmall,
    AppLoader,
    UniqueEmail,
    AppButton, ModalHeader,
    AppModalMiddle, AppTable,
    IconPlus, OfferListHeader,
    AppMainContainer, AppInput
  },
  mixins: [officeApi],
  data () {
    return {
      querySearch: null,
      loaderData: {
        USER_FORM: 'USER_FORM',
        USER_LIST: 'USER_LIST',
      },
      currentLoader: null,
      selectedOfficeList: [],
      isScroll: false,
      currentModal: '',
      hasMultipleRoles: false,
      inviteEmail: {
        value: '',
        isValid: false,
      },
      modalName,
      keys: [
        { label: '', value: 'statusIcon', config: { canSort: false } },
        { label: 'Имя пользователя', value: 'firstName', config: { canSort: true } },
        { label: 'Должность', value: 'profession', config: { canSort: true } },
        { label: 'Электронная почта', value: 'email', config: { canSort: true } },
        { label: 'Заблокирован', value: 'idBlocked', valueProp: 'title', config: { canSort: false } },
        // { label: 'Объект', value: 'office', valueProp: 'title', config: { canSort: false } },
        { label: 'Роль', value: 'role', valueProp: 'title', config: { canSort: false } },
      ],
      userFields: [],
      // roles: [
      //   { 'id': 'ROLE_ORGANIZATION_ADMIN', 'title': 'Администратор объектов' },
      //   { 'id': 'ROLE_ORGANIZATION_MANAGER', 'title': 'Менеджер объекта' },
      // ]
    }
  },

  computed: {
    roles () {
      if (!this.currentUser) {
        return []
      }
      return userUtil.isRoot(this.currentUser) ? [
        { 'id': 'ROLE_ORGANIZATION_ADMIN', 'title': 'Администратор объектов' },
        { 'id': 'ROLE_ORGANIZATION_MANAGER', 'title': 'Менеджер объекта' },
      ]
        : [
          { 'id': 'ROLE_ORGANIZATION_MANAGER', 'title': 'Менеджер объекта' },
        ]
    },
    currentUser () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.currentUser) || userUtil.isAdmin(this.currentUser)
    },
    officeExists () {
      return this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
    },
    loadList () {
      return this.currentLoader === this.loaderData.USER_LIST
    },
    userList () {
      const list = this.$store.state.users.items
      if (!this.querySearch || this.querySearch.length < 2) {
        return list
      }
      return list.filter((item) => {
        return this.filterSearch(item, this.querySearch, '')
      })
    },
    officeList () {
      return this.$store.state.office.items
    },
    selectedList () {
      return {
        roles: this.roles,
        objects: this.officeList
      }
    },

    isFormValid () {
      const validationMap = this.userFields.filter((item) => item.validator).map((item) => item.validator)
      const allValidateFields = validationMap
      // const isValidFields = allValidateFields.filter((value) => value.every((el) => el.valid))
      const isValidFields = allValidateFields.filter((value) => value.every((el) => el.valid))

      return allValidateFields.length === isValidFields.length && this.inviteEmail.isValid
    }
  },
  destroyed () {
    this.$store.commit('users/setUserList', [])
  },
  created () {
    this.initUserFieldList()
    this.currentLoader = this.loaderData.USER_LIST
    this.fetchUserList()
  },

  methods: {
    initUserFieldList () {
      this.userFields = [
        {
          type: AppInput, placeholder: 'Фамилия (обязательное поле)', value: '', key: 'lastName',
          validator: [
            { text: 'Заполните Фамилию', valid: false, validator: (value) => notEmptyField(value) }
          ]
        },
        {
          type: AppInput, placeholder: 'Имя (обязательное поле)', value: '', key: 'firstName',
          validator: [
            { text: 'Заполните имя', valid: false, validator: (value) => notEmptyField(value) }
          ]
        },
        { type: AppInput, placeholder: 'Отчество', value: '', key: 'middleName' },
        { type: AppInput, placeholder: 'Должность', value: '', key: 'profession' },
        // {
        //   type: AppInput, placeholder: 'Электронная почта (обязательное поле)', value: '', key: 'email',
        //   validator: [
        //     { text: 'Заполните электронную почту', valid: false, validator: (value) => notEmptyField(value) },
        //     { text: 'Не соотвествует формату ', valid: false, validator: (value) => validateEmail(value) }
        //   ]
        // },
        {
          type: AppInput,
          placeholder: 'Телефон',
          value: '',
          key: 'telephone',
          showValidationMessage: true,
          format: '+7 (NNN) NNN NN NN',
          validator: [
            {
              text: 'Неверный формат телефона (+79ХХХХХХХХХ)',
              valid: true, validator: (value) => ruPhoneValidator(value)
            },
          ],
        },
        {
          type: AppSelect, placeholder: 'Роль (обязательное поле)', value: '', key: 'role', values: 'roles',
          visualValue: 'title',
          validator: [
            { text: 'Заполните роль', valid: false, validator: (value) => notEmptyField(value) }
          ]
        },
        // {
        //   type: AppSelect, placeholder: 'Выберите объект', value: '', key: 'office', values: 'objects',
        //   visualValue: 'title'
        // },
      ]
    },
    onSearchBlur () {
    },
    onSearch ($event) {
    },
    filterSearch (item, queryText, itemText) {
      const searchText = queryText.toLowerCase()
      const username = item.email.toLowerCase()
      const fullName = item.fullName ? item.fullName.toLowerCase() : ``
      const telephone = item.telephone ? item.telephone.toLowerCase() : ``

      return username.indexOf(searchText) > -1 ||
        fullName.indexOf(searchText) > -1 ||
        telephone.indexOf(searchText) > -1
    },
    fetchUserList () {
      return this.$store.dispatch('users/fetchUserList').then((response) => {
        this.currentLoader = null
        return response
      })
    },
    goToUserEdit (user) {
      return this.$router.push({ name: 'userItem', params: { userId: user.id } })
    },
    onEmailValueChange (value) {
      this.inviteEmail = value
    },
    showModalCreateUser () {
      this.eventsBus.$emit(this.eventNames.CLEAR_UNIQUE_EMAIL)

      this.$store.dispatch('office/fetchOfficeListWithCommit', this.filters)
      this.currentModal = this.modalName.createUser
    },
    inviteUser () {
      const data = this.userFields.map((item) => ({ key: item.key, value: item.value }))
        .reduce((result, val) => ({
          ...result, [val.key]: val.value
        }), {})

      //
      // // TODO временое решение
      // data.role = 'ROLE_ORGANIZATION_MANAGER'
      if (data.telephone) {
        data.telephone.replace('+', '')
      }

      if (data.role) {
        data.role = data.role.id
      }

      data.email = this.inviteEmail.value
      data.officeList = this.selectedOfficeList

      this.currentLoader = this.loaderData.USER_FORM
      this.$store.dispatch('users/fetchCreateUserData', data)
        .then(() => {
          this.closeModal()
          this.currentModal = this.modalName.successCreateUser
          this.userFields.forEach((item) => {
            item.value = ''
          })
          this.selectedOfficeList = []

          this.fetchUserList()
          this.currentLoader = null
        }).catch(() => {
        // TODO отображать ошибку
          this.currentLoader = null
        })
    },
    closeModal () {
      this.currentModal = ''
      this.initUserFieldList()
    },
    onFieldChange ($event, name) {
      if (name === 'role' && $event && $event.id === 'ROLE_ORGANIZATION_ADMIN') {
        this.eventsBus.$emit('clearOfficeMember')
        this.hasMultipleRoles = true
      } else {
        this.hasMultipleRoles = false
        this.eventsBus.$emit('clearOfficeMember')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.filter-button
  display: flex
  align-items: center
  width: 400px
  margin-top: 20px


.user-list__circle
  background-color: #DDDDDD
  width: 10px
  height: 10px
  border-radius: 20px
  &_active
    background-color: #8BD118
  &_blocked
    background-color: red

.user-form__input-group
  margin-top: 15px
  padding-bottom: 15px
  border-bottom: $default_border_bottom
.input-group__input
  margin-top: 15px

.user-form__button-group
  display: flex
  margin-top: 30px
</style>
