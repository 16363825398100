<template>
  <div>
    <p style="visibility: hidden">
      {{ currentUser ? currentUser.id : '' }}
    </p>
    <h3
      v-if="!editable"
      class="item-header"
    >
      {{ `Список объектов (${localSelectedOfficeList.length})` }}
    </h3>
    <AppSelect
      v-if="canAdd"
      v-model="currentOffice"
      style="margin: 15px auto"
      :visualValue="currentOffice ? currentOffice.title : ''"
      :placeholder="'Выберите объект'"
      class="office-select mr-2"
      :values="availableOfficeList"
      @change="onOfficeChange"
    >
      <template #default="{value}">
        {{ value.title }}
      </template>
    </AppSelect>
    <div :class="{'divider-line': localSelectedOfficeList.length}">
      <AppButton
        v-if="allowAdd"
        style="margin-left: auto; margin-top: 15px; margin-bottom: 5px"
        @click.native="addOffice"
      >
        {{ localSelectedOfficeList.length ? `Добавить еще объект` : 'Добавить объект' }}
      </AppButton>
    </div>
    <div>
      <div
        v-for="office in localSelectedOfficeList"
        :key="office.id"
        class="office-list-wrapper item-divider-line"
      >
        <p>{{ office.title }}</p>
        <IconClose
          v-if="editable"
          :width="18"
          :height="18"
          fill="red"
          @click.native="removeOffice(office)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppSelect from '@/components/common/simple/AppSelect'
import AppButton from '@/components/common/simple/AppButton'
import IconClose from '@/components/common/icons/IconClose'

export default {
  name: 'OfficeMember',
  components: {
    IconClose,
    AppSelect,
    AppButton,
  },
  props: {
    officeList: {
      type: Array
    },
    editable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    selectedOfficeList: {
      type: Array
    }
  },
  data () {
    return {
      localSelectedOfficeList: [],
      currentOffice: null,
      canAdd: false,
    }
  },
  computed: {
    allowAdd () {
      const valid = this.availableOfficeList.length && this.editable && !this.canAdd
      if (!this.multiple) {
        return this.localSelectedOfficeList.length === 0 && valid
      } else {
        return valid
      }
    },
    currentUser () {
      const user = this.$store.state.users.editedUser
      if (user && Array.isArray(user.officeList)) {
        const vm = this
        vm.localSelectedOfficeList = [
          ...user.officeList
        ]
      }

      return this.$store.state.users.editedUser
    },
    availableOfficeList () {
      return this.officeList.filter((item) => !this.localSelectedOfficeList.map(
        (selected) => selected.id).includes(item.id)
      )
    },
  },
  beforeDestroy () {
    this.$store.commit('users/setEditedUser', null)
    this.localSelectedOfficeList = []
    this.canAdd = false
  },
  created () {
    this.eventsBus.$on('clearOfficeMember', this.clear)
  },
  methods: {
    addOffice () {
      this.canAdd = true
    },
    clear () {
      this.localSelectedOfficeList = []
      this.updateSelectedOfficeList()
    },
    updateSelectedOfficeList () {
      this.$emit('update:selectedOfficeList', this.localSelectedOfficeList.map((item) => ({ id: item.id })))
    },
    removeOffice (office) {
      this.localSelectedOfficeList = this.localSelectedOfficeList.filter((item) => item.id !== office.id)
      this.updateSelectedOfficeList()
    },
    onOfficeChange (office) {
      this.localSelectedOfficeList.push(office)
      this.updateSelectedOfficeList()
      this.canAdd = false
      this.currentOffice = null
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="sass" scoped>
.office-list-wrapper
  margin: 10px auto
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center

.divider-line
  border-bottom: $default_border_bottom
  margin-bottom: 15px

.item-divider-line
  border-bottom: $default_border_bottom
  padding-bottom: 8px
  opacity: 0.4

.item-header
  margin: 15px auto
  @extend %title-text
</style>
